<template>
  <div class="container">
    <div class="cont">
      <div class="title-top"><span class="el-icon-arrow-left" @click="$router.back()" style="cursor:pointer"></span> 帮助中心</div>
      <div class="collapse">
        <el-collapse v-model="activeNames">
          <el-collapse-item :title="item.name" :name="item.id" v-for="item in list" :key="item.id">
            <div v-html="item.center"></div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list:[],
      qq: "3692073479",
      email: "2748409013@qq.com",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://help.steampowered.com/zh-cn/wizard/Login?redir=%2Fzh-cn%2Fwizard%2FHelpWhyCantITrade&title=%E7%99%BB%E5%BD%95",
    };
  },
  methods: {
    //获得steam交易链接
    goUrl(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.$axios({
      url: '/api/index/textconfig',
      method: 'post'
    }).then((res) => {
      if(res.data.code == 1) {
        this.list = res.data.data
      }
    })
  }
};
</script>

<style lang='less' scoped>
.container {
  color: white;
  width: 100%;
  min-height: calc(100vh - 175px);
  display: flex;
  justify-content: center;
  background-color: #1d1d26;
  @media (max-width: 550px) {
    min-height: calc(100vh - 145px);
  }
  .cont {
    @media (max-width: 550px) {
      width: 95%;
    }
    width: 75%;
    height: 100%;
    margin-top: 30px;
    // background-color: aqua;
    .title-top {
      padding-bottom: 15px;
      border-bottom: 1px solid rgb(83, 82, 82);
    }
    .collapse {
      margin-top: 5px;
    }
  }
}

.doubt1 {
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		// background-color: #1a1c24;
		// padding-top: 42px;

		.doubt-warp {
			// width: 1200px;
			margin: 0 auto;
			// padding-top: 88px;
			// background-color: #2b2c37;
			padding: 32px;
		}

		.title {
			padding: 20px 0 64px 0;
			font-size: 30px;
			color: #c3c3e2;
		}

		.con {
			font-size: 18px;
			color: #848492;

			span {
				display: inline-block;
				margin-top: 10px;
			}
		}
	}


.doubt2 {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background-color: #1a1c24;
  // padding-top: 42px;

  .doubt-warp {
    // width: 1200px;
    margin: 0 auto;
  }

  .title {
    // padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }

  .con {
    margin-top: 46px;
    margin-bottom: 88px;
    font-size: 18px;
    color: #848492;

    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }

  .title1 {
    color: #c3c3e2;
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.doubt3 {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background-color: #1a1c24;
  // padding-top: 12px;
  .doubt-warp {
    // width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    padding-bottom: 88px;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
}

.doubt4 {
  .doubt-warp {
    // width: 1200px;
    margin: 0 auto;
    // padding: 32px;
  }
  .title {
    // padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .title1 {
    font-size: 20px;
    color: #c3c3e2;
    padding: 16px 0;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      margin-top: 10px;
      display: inline-block;
    }
  }
}

.doubt-warp5 {
  // width: 1200px;
  margin: 0 auto;
  // padding: 20px 0;
  margin-bottom: 50px;

  ul li {
    .name {
      color: #c3c3e2;
      font-size: 24px;
      font-weight: 600;
    }

    .name-title {
      font-size: 20px;
      // margin: 20px 0 0 20px;
      color: #c3c3e2;
    }

    .p {
      font-weight: 400;
      font-size: 16px;
      color: #848492;
      // padding: 8px 0;
    }

    .title-p {
      color: #848492;
      // margin: 20px 0 0 20px;
    }
  }

  .title1 {
    // padding: 20px 0 0 40px;
    font-size: 16px;
    color: #c3c3e2;
    font-weight: 600;
  }

  .title2 {
    // padding: 20px 0 0 60px;
    font-size: 16px;
    color: #848492;

    span {
      text-decoration: underline;
      color: #17b4ed;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .title3 {
    // padding: 20px 0 0 60px;
    font-size: 14px;
    color: #c3c3e2;
    font-weight: 600;
  }

  .title4 {
    // padding: 20px 0 0 60px;
    font-size: 14px;
    color: #848492;
    font-weight: 600;

    span {
      text-decoration: underline;
      color: #17b4ed;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .img {
    // padding: 20px 0 0 60px;
    img{
      @media (max-width: 550px) {
      width: 90vw !important;
      height: auto;
    }
    }
  }
}

::v-deep .el-collapse-item__header {
  background-color: #0e0d0e;
  border: none;
  margin-top: 5px;
  color: white;
  height: 40px;
  // margin-left: 5px;
  padding-left: 10px;
}
::v-deep .el-collapse-item__header .is-active {
  background-color: #0e0d0e;
  color: white;
}
::v-deep .el-collapse-item__wrap {
  background-color: #0e0d0e;
  border: none;
  color: white;
  padding: 0 10px;
  div {
    color: white;
    font-size: 16px;
  }
}
::v-deep .el-collapse-item__wrap .is-active {
  background-color: #0e0d0e;
  border: none;
  color: white;
}
::v-deep .el-collapse {
  border: none;
  color: white;
}
</style>